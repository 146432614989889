
<template>
  <v-card
    fill-height
    :min-height="`${height}`"
    class="data-card d-flex"
    @click="open()"
  >
    <v-img class="card-image" min-width="100%" max-height="100%" :src="url" cover/>
      <v-col class="data-card">
        <div class="category">
          <img src="../../assets/check_mark.svg" alt="Check Mark">
          {{category}}
        </div>
        <div class="title">{{title}}</div>
      </v-col>
  </v-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    id: Number,
    title: String,
    url: String,
    category: String,
    shadow:{
      value:Boolean,
      default:true
    },
    file:{
      value:Object,
      default:{}
    },
    type:{
      value:String,
      default:"news"
    },
    height: Number,
    width: Number
  }, 
  methods: {
    open() {
      this.$emit("open", this.id);
    },
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none;
  width: 100%;
  
  .title {
    font-family: 'Inter', sans-serif !important;
    font-size: 24px !important;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
    margin: 14px 22px;
    margin-top: 8px;
    max-width: 470px;
  }
  .data-card {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left;
    padding: 0; 
  }
  .card-image {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
  .category {
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.5px;
    background: #334666;
    padding: 4px 11px;
    border-radius: 27px;
    margin-left: 22px;
    color: #fff;
    font-weight: 700;

    img {
      margin-right: 7px;
    }
  }
}
</style>